import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { displayErrorInfo, displayServerError } from "@/helpers/alertHelpers";

export interface IAdmin {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  password: string;
  email: string;
}

export interface AdminInfo {
  adminData: Array<IAdmin>;
  adminSelectData: IAdmin;
}

@Module
export default class AdminModule extends VuexModule implements AdminInfo {
  adminData = [] as Array<IAdmin>;
  adminSelectData = {} as IAdmin;

  /**
   * Get current user object
   * @returns AdminList
   */
  get adminList(): Array<IAdmin> {
    return this.adminData;
  }

  /**
   * Get current user object
   * @returns SelectedAdminData
   */
  get getAdminSelected(): IAdmin {
    return this.adminSelectData;
  }

  @Mutation
  [Mutations.SET_ADMIN.LIST](adminData) {
    this.adminData = adminData;
  }

  @Mutation
  [Mutations.SET_ADMIN.SELECT](data) {
    this.adminSelectData = data;
  }

  @Action
  [Actions.ADMIN.LIST]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("admins")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN.LIST, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError(response, "List Admin accounts");
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.ADMIN.CREATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("admins", payload)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Create Admin account");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.ADMIN.UPDATE](item) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update("admins", item.id, item)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Update Admin account");
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.ADMIN.DELETE](id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.delete("admins/" + id)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Delete Admin account");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.ADMIN.CHANGE_PASSWORD](item) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update("admins/change-password", item.id, item)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Change admin password");
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.ADMIN.CREATE_TEMP_USER](item) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("admins/temp-user", item)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            displayErrorInfo(response);
          } else {
            displayServerError(response, "create temp userAccount");
          }
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
